import { useTranslations } from "use-intl"
import { Autosuggest } from "components/Autosuggest"
import { FilterBox, useFacetBox } from "."
import { Section } from "components/Templates"
import { Fieldset, Select } from "components/Form"
import { ModalWrapper } from "components/ModalWrapper"
import { useAvoidFirstRender, usePrevious } from "hooks"

import Link from "next/link"
import { useRouter } from "next/router"
import { useCallback, useEffect, useMemo, useState, useRef } from "react"
import { useForm } from "react-hook-form"
import { useDispatch } from "react-redux"
import { format } from "date-fns"
import { setArchiveSlice, setJobsSlice } from "state"
import { getDefaultValues, fetchSuggestions, getQueryString } from "utils"
import { stateNameList } from "const"
import { yupResolver } from "@hookform/resolvers/yup"
import { getTitleCase, push } from "utils"
import * as yup from "yup"
import { jobSearchSchema } from "utils/validationSchema"

export function useFindJobs({
  which, // home || jobs || archive
  tw,
  showHeading,
  initialQuery,
  facetsFromAzure,
}) {
  const dispatch = useDispatch()
  const t = useTranslations("FindJobs")
  const s = useTranslations("Shared")

  if (typeof s.raw("yup") !== "undefined") {
    yup.setLocale(s.raw("yup"))
  }

  const router = useRouter()
  const isHomepage = which === "homepage"
  const filters = [
    "radius",
    "wage",
    "start_date",
    "job_type",
    "job_status",
    "sort",
    "facets",
  ]

  // modal props (Filterbox)
  const [open, setOpen] = useState(false)

  const onOpenModal = () => setOpen(true)
  const onCloseModal = () => setOpen(false)
  // end modal props

  let schema = jobSearchSchema(s)

  const {
    register,
    handleSubmit,
    control,
    setValue,
    value,
    watch,
    reset,
    trigger,
    formState: { errors, isValid },
  } = useForm({
    shouldFocusError: false,
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: getDefaultValues(which),
    mode: "onChange",
  })

  const { FacetBox, setSelectedFacets } = useFacetBox({
    control,
    setValue,
    facets: facetsFromAzure,
  })

  const handlePushToGA = ({ query, action }) => {
    const isFilter = action.startsWith("filter")
    const hasSearchInputs = query.search || query.location
    if (query?.start_date) {
      query.start_date = query.start_date.toString()
    }
    if (hasSearchInputs || isFilter) {
      push({
        event: `${action}Jobs`,
        page: which,
        action,
        query,
      })
    }
  }

  const arrayDiffIndex = function (a, b) {
    let diffIndexes = []
    a?.forEach(function (val, index) {
      if (b[index] !== val) {
        diffIndexes.push(index)
      }
    })
    return diffIndexes[0]
  }

  const stringifyArray = function (array) {
    return array.map((val) =>
      (typeof val === "object" && Array.isArray(val)) || !val
        ? ""
        : val.toString()
    )
  }

  const onSubmit = useCallback(
    async (data, e) => {
      // send search data to google analytics on button press or search filled in
      handlePushToGA({ query: data, action: "search" })

      const domPurify = (await import("isomorphic-dompurify")).default
      if (which === "jobs") {
        dispatch(
          setJobsSlice({
            search: domPurify ? domPurify.sanitize(data.search) : data.search,
            location: domPurify
              ? domPurify.sanitize(data.location)
              : data.location,
          })
        )
      } else if (which === "archive") {
        dispatch(
          setArchiveSlice({
            search: domPurify ? domPurify.sanitize(data.search) : data.search,
            location: domPurify
              ? domPurify.sanitize(data.location)
              : data.location,
          })
        )
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, which]
  )

  // INIT: Update form values on mount from initial props
  useEffect(() => {
    if (initialQuery) {
      Object.keys(initialQuery).map((key) => {
        let value = initialQuery[key]
        if (key === "start_date") {
          value = value ? new Date(value) : ""
        }
        setValue(key, value)
      })
      const { search: s, location: l } = initialQuery
      trigger(["search", "location"]) // Validate search and location fields on mount
      handleSubmit(onSubmit({ search: s, location: l }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialQuery, dispatch, which, setValue])

  const current = watch()

  // slight data type changes causing rerenders, normalize it
  const watchFilters = watch(filters)
  const normalizeWatchedFilters = stringifyArray(watchFilters)
  // create a hash to use as dep in useEffect
  const normalizedWatchedFiltersHash = JSON.stringify(normalizeWatchedFilters)
  const prevAmount = usePrevious(watchFilters)
  // avoid sending query to GA on first render when landing on Jobs page
  useAvoidFirstRender(() => {
    const indexFilterChanged = arrayDiffIndex(prevAmount, watchFilters)
    let filterChanged = filters[indexFilterChanged]
    if (filterChanged) {
      filterChanged = filterChanged
        .split("_")
        .map((str) => getTitleCase(str))
        .join("")
      handlePushToGA({ query: current, action: `filter${filterChanged}` })
    }
  }, [normalizedWatchedFiltersHash])

  const {
    search,
    location,
    start_date,
    job_type,
    radius,
    wage,
    job_status,
    facets,
    sort,
  } = current

  // URL SYNC: Sync url params with current field values
  useEffect(() => {
    if (which !== "homepage") {
      let params = {
        search: search || "",
        location: location || "",
        start_date: start_date
          ? format(new Date(start_date), "MM/dd/yyyy")
          : "",
        job_type: job_type || "",
        sort: sort || "relevancy",
      }

      if (which === "jobs") {
        params = {
          ...params,
          radius: radius || 100,
          wage: wage || "all",
          facets: facets || [],
        }
      } else if (which === "archive") {
        params = { ...params, job_status: job_status || "" }
      }

      const queryString = getQueryString(params)
      router.push(`/${which}?${queryString}`, null, { shallow: true })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    search,
    location,
    start_date,
    job_type,
    radius,
    wage,
    job_status,
    facets,
    sort,
    which,
  ])

  return {
    register,
    watch,
    FindJobs: useMemo(() => {
      const renderFacetBox = () => {
        if (which === "jobs") {
          return FacetBox
        } else {
          return null
        }
      }

      return (
        <Section
          id="find-jobs"
          heading={showHeading && t("heading")}
          headingTw="text-green-800 font-bold"
          tw={tw}
          gridContainerHeading={isHomepage || false}
          gridContainer={false}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-col md:flex-row space-x-6 space-y-6 md:space-y-0 mt-4 grid-container">
              <Fieldset className="w-full md:w-6/12 self-end">
                <Autosuggest
                  name="search"
                  translationName="Search"
                  label={t("Search.label")}
                  helper={t("Search.helper")}
                  control={control}
                  setValue={setValue}
                  fetcher={fetchSuggestions}
                  which={which}
                  isValid={isValid}
                  errors={errors}
                />
              </Fieldset>
              <Fieldset className="w-full md:w-4/12 self-end">
                {which !== "archive" ? (
                  <Autosuggest
                    name="location"
                    translationName="Location"
                    label={t("Location.label")}
                    helper={t("Location.helper")}
                    control={control}
                    setValue={setValue}
                    fetcher={fetchSuggestions}
                    which={which}
                    isValid={isValid}
                    errors={errors}
                  />
                ) : (
                  <Select
                    register={register}
                    name="location"
                    label={t("Location.label")}
                    values={["", ...stateNameList]}
                    options={[t("Location.select"), ...stateNameList]}
                  />
                )}
              </Fieldset>
              <div className="w-full md:w-2/12 flex flex-col self-end">
                {isHomepage ? (
                  <Link
                    href={`/jobs?search=${encodeURIComponent(
                      current.search
                    )}&location=${encodeURIComponent(current.location)}`}
                    passHref
                  >
                    <button
                      className="usa-button w-full md:-mt-8 bg-green-800 hover:bg-green-900"
                      type="submit"
                    >
                      {t("button")}
                    </button>
                  </Link>
                ) : (
                  <>
                    <button
                      className="usa-button usa-button--outline mb-3 mr-0 md:hidden md:mb-0"
                      type="button"
                      onClick={onOpenModal}
                    >
                      Filters
                    </button>
                    <button
                      className={`usa-button w-full md:-mt-8 ${
                        isValid
                          ? "bg-green-800 hover:bg-green-900"
                          : "bg-slate-50 text-slate-500 border-slate-200 shadow-none cursor-not-allowed"
                      }`}
                      type="submit"
                      disabled={!isValid}
                      aria-disabled={!isValid}
                    >
                      {t("button")}
                    </button>
                  </>
                )}
              </div>
            </div>
            {!isHomepage ? (
              <>
                <ModalWrapper
                  filterModal={true}
                  open={open}
                  onCloseModal={onCloseModal}
                >
                  <FilterBox
                    register={register}
                    control={control}
                    reset={reset}
                    which={which}
                    enableRadius={current?.location?.length > 1}
                    setValue={setValue}
                    setSelectedFacets={setSelectedFacets}
                  />
                </ModalWrapper>
                {renderFacetBox()}
              </>
            ) : null}
          </form>
        </Section>
      )
    }, [
      showHeading,
      t,
      tw,
      isHomepage,
      handleSubmit,
      onSubmit,
      control,
      setValue,
      which,
      register,
      current.search,
      current.location,
      open,
      reset,
      setSelectedFacets,
      FacetBox,
    ]),
    isValid,
  }
}
